import React from 'react'
import {withStyles} from '@material-ui/core/styles'
import {palette} from 'theme'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import {Link} from 'react-router-dom'
import {ReactComponent as Logo} from 'assets/anymeets-logo.svg'
import {Box} from '@material-ui/core'
//import downloadFromAppStore from 'assets/download-on-the-app-store-apple.svg'
//import downloadFromPlayStore from 'assets/google-play-download-android-app.svg'
//import {APP_ANDROID_INFO, APP_APPLE_INFO} from 'config'

const styles = theme => ({
  container:{
    backgroundColor:'#f4f4f4',
    paddingTop:theme.spacing(5),
    paddingBottom:theme.spacing(3),
    [theme.breakpoints.down('sm')]:{
      paddingBottom:theme.spacing(8),
      height:'auto',
      marginBottom:theme.spacing(6)
    }
  },
  navLinks: {
    fontSize: '14px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.71',
    letterSpacing: '0.12px',
    color: palette['greyish-brown'],
    textDecoration:'none'
  },
  navLinkTitle: {
    fontWeight: "bold",
    fontSize: '0.95rem',
  },
  hideMobile: {
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },
  centerMobile: {
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center'
    }
  },
  downloadButton: {
    maxWidth: '150px',
    height: 'auto',
    marginBottom:theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      maxWidth: '40%',
      minWidth: '200px'
    }
  }
})


const SubFooter = withStyles(styles)(({classes}) => {
return (
	<Box className={classes.container}>
		<Container maxWidth="md">
			<Grid
				container
				direction={"row"}
				justify={"flex-start"}
				alignItems={"flex-start"}
				alignContent={"center"}
				spacing={4}
			>
				<Grid item lg={4} xs={12}>
					<Logo />
					<Box mt={2}>
						<Typography className={classes.navLinks}>
							An all-inclusive conference management platform with support for
							virtual and hybrid events.
						</Typography>
					</Box>
					<Box mt={4}>
						<Typography className={classes.navLinks}></Typography>
					</Box>
				</Grid>
				<Grid item lg={3} sm={4} xs={4}>
					{/* <Box mt={1}>
						<Typography
							variant={"subtitle2"}
							color={"initial"}
							className={classes.navLinkTitle}
						>
							Features
						</Typography>
					</Box>
					<Box mt={2}>
						<Typography>
							<Link to={"/conference"} className={classes.navLinks}>
								Conference Page
							</Link>
						</Typography>
					</Box>
					<Box mt={1}>
						<Typography>
							<Link to={"/papers"} className={classes.navLinks}>
								Call for Papers
							</Link>
						</Typography>
					</Box>
					<Box mt={1}>
						<Typography>
							<Link to={"/virtual-events"} className={classes.navLinks}>
								Virtual Event
							</Link>
						</Typography>
					</Box>
					<Box mt={1}>
						<Typography>
							<Link to={"/post-events"} className={classes.navLinks}>
								Post Event
							</Link>
						</Typography>
					</Box> */}
				</Grid>
				<Grid item lg={2} sm={4} xs={4}>
					{/* <Box mt={1}>
						<Typography
							variant={"subtitle2"}
							color={"initial"}
							className={classes.navLinkTitle}
						>
							About
						</Typography>
					</Box>
					<Box mt={2}>
						<Typography>
							<Link to={"/about"} className={classes.navLinks}>
								About Us
							</Link>
						</Typography>
					</Box>
					<Box mt={1}>
						<Typography>
							<Link to={"/pricing"} className={classes.navLinks}>
								Pricing
							</Link>
						</Typography>
					</Box>
					<Box mt={1}>
						<Typography>
							<Link to={"/contact"} className={classes.navLinks}>
								Contact
							</Link>
						</Typography>
					</Box>
					<Box mt={1}>
						<Typography>
							<Link to={"/privacy"} className={classes.navLinks}>
								Privacy Policy
							</Link>
						</Typography>
					</Box> */}
				</Grid>
				<Grid item lg={3} sm={4} xs={4}>
					{/* <Box mt={1}>
						<Typography
							variant={"subtitle2"}
							color={"initial"}
							className={classes.navLinkTitle}
						>
							Resources
						</Typography>
					</Box>
					<Box mt={2}>
						<Typography>
							<a
								href={"https://docs.anymeets.com/"}
								className={classes.navLinks}
								target={"blank"}
							>
								How-to Guides
							</a>
						</Typography>
					</Box>
					<Box mt={1}>
						<Typography>
							<a
								href={"https://docs.anymeets.com/en/faq"}
								className={classes.navLinks}
								target={"blank"}
							>
								FAQ
							</a>
						</Typography>
					</Box> */}
					{/*<Box mt={1}>
						<Typography>
							<Link to={"/search"} className={classes.navLinks}>
								Events
							</Link>
						</Typography>
					</Box>*/}
				</Grid>
				{/*<Grid item lg={2}>
          <Box>
            <a href={APP_APPLE_INFO.linkUrl} title={APP_APPLE_INFO.infoText}>
                 <img src={downloadFromAppStore} alt='Download our app from the App store' className={classes.downloadButton}/>
           </a>
          </Box>
          <Box>
            <a href={APP_ANDROID_INFO.linkUrl} title={APP_ANDROID_INFO.infoText}>
              <img src={downloadFromPlayStore} alt='Download our app from the Play store' className={classes.downloadButton}/>
            </a>
          </Box>
          </Grid>*/}
			</Grid>
		</Container>
	</Box>
);
})

export default  SubFooter
